// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ArticleListingPage/ArticleListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-article-page-article-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-article-page-article-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-children-hub-page-children-hub-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ChildrenHubPage/ChildrenHubPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-children-hub-page-children-hub-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/Error404Page/Error404Page.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductListPage/ProductListPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SearchPage/SearchPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SymptomsPage/SymptomsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-where-to-buy-page-where-to-buy-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/WhereToBuyPage/WhereToBuyPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-where-to-buy-page-where-to-buy-page-tsx" */)
}

